<template>
<div class="content md">
</div>
</template>

<script>
export default {
  name: "Subsystem",
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
</style>
