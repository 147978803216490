<template>
  <data-list
      ref="dataList"
      class="content"
      :class="conf.listClass"
      add-route="AddEntity"
      :add-btn="addBtn"
      :url="conf.url"
      :entity-name="conf.name"
      :query="configQuery"
      :columns="columns"
      :default-query="conf.defaultQuery"
      :search-key-type="searchKeyType"
      :deal-list="conf.dealList"
      :action="action"
      :show-row-num="conf.showRowNum"
      :row-selection="rowSelection"
      :hide-query="conf.hideQuery"
      :hide-edit="conf.hideEdit"
      :hide-delete="conf.hideDelete"
      :request-config="conf.requestConfig"
      :custom-url="conf.customUrl"
      :custom-delete="conf.customDelete"
      @detail="showDetail"
      @edit="showEdit"
      v-if="conf && auto">
    <template v-slot:button v-if="conf.batchDeleteUrl">
      <a-button type="danger" @click="batchDelete">批量删除</a-button>
    </template>
    <template v-slot:button v-else-if="conf.buttons">
      <span v-for="(btn, index) in conf.buttons" :key="index">
          <component
              :is="btn.component"
              :style="btn.style"
              v-model="btn.value"
              v-bind="btn.props"
              v-on="encapsulationListeners(btn.listeners)">{{btn.name}}
          </component>
      </span>
  </template>
    <template v-slot:query="{form}" v-if="conf.queryForm">
      <a-form-model-item :label="item.label" v-for="item in conf.queryForm" :key="item.key">
        <component
            :is="item.component"
            :style="item.style"
            v-model="form[item.key]"
            v-bind="item.props"
            v-on="getListeners(item)">
        </component>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import entityConfig from "../common/constant/entity";
import {goToDetailRoute} from "../common/js/tool";
import {getTempMenu} from "@/common/constant/template";

export default {
  name: "EntityList",
  data() {
    return {
      auto: true,
      selectedRowKeys: []
    }
  },
  computed: {
    type() {
      return this.$route.params.type
    },
    conf() {
      let type = this.type;
      return entityConfig[type];
    },
    searchKeyType() {
      let conf = this.conf;
      return conf && conf.searchKeyType || {name: 1, title: 1};
    },
    columns() {
      let conf = this.conf;
      return conf && conf.columns ? conf.columns(this) : [];
    },
    update() {
      return this.$store.getters.update;
    },
    configQuery() {
      let conf = this.conf;
      if(typeof conf.query === 'function') {
        return conf.query.call(this);
      } else {
        return conf.query;
      }
    },
    action() {
      // 遍历匹配的路由找到公共实体路由的action配置
      let matched = this.$route.matched;
      let route = matched.find(r => r.components.default.name == 'Entity');
      let res = null;
      if(route && route.meta && route.meta.action) {
        let type = this.type;
        res = route.meta.action[type] || null;
      }
      return res;
    },
    temp() {
      // 遍历匹配的路由找到公共实体路由的temp配置
      let matched = this.$route.matched;
      let route = matched.find(r => r.components.default.name == 'Entity');
      let res = null;
      if(route && route.meta && route.meta.tempMap) {
        let type = this.type;
        res = route.meta.tempMap[type] || null;
      }
      return res;
    },
    rowSelection() {
      const conf = this.conf;
      return conf.batchDeleteUrl ? {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
      } : null;
    },
    addBtn() {
      const conf = this.conf;
      return conf.name ? `添加${conf.name}` : "";
    }
  },
  watch: {
    update(val) {
      if(val && val.type == this.type) {
        this.updateList();
        this.$store.commit("changeUpdate", null);
      }
    },
    conf() {
      // 缓存路由需判断当前路由是否显示
      if(this.$route.name == 'List') {
        this.init();
      }
    },
    action() {
      // 缓存路由需判断当前路由是否显示
      if(this.$route.name == 'List') {
        this.checkPermission();
      }
    },
    temp() {
      // 缓存路由需判断当前路由是否显示
      if(this.$route.name == 'List') {
        this.setTempMenu();
      }
    }
  },
  created() {
    this.checkPermission(true);
    this.setTempMenu();
  },
  methods: {
    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },
    batchDelete() {
      const keys = this.selectedRowKeys;
      const conf = this.conf;
      if(keys.length > 0) {
        this.$confirm({
          title: '提示',
          content: `确认删除这${keys.length}${conf.unit || '条'}${conf.name}吗？`,
          onOk: () => {
            const url = conf.batchDeleteUrl;
            this.$axios({
              url,
              method: "POST",
              data: {
                  ids: keys.join(",")
              },
              noTempleFilter: true
            }).then(res => {
              if(res.code == 0) {
                this.$message.success("批量删除成功");
                this.selectedRowKeys = [];
                this.updateList();
              } else {
                this.$message.warning(res.msg || "批量删除失败");
              }
          })
          }
        });
      } else {
        this.$message.warning("请至少选择一条数据");
      }
    },
    // 如果实体关联到模板的后台菜单 则根据模板实体更新菜单
    setTempMenu() {
      let temp = this.temp;
      if(temp) {
        let current = this.$store.getters.temp;
        if(current && current.type == temp.type) {
          return;
        } else {
          this.$axios(`/admin/apply-temp?pageSize=1&filter[type]=${temp.type}`).then(res => {
            if(res.data.length > 0) {
              this.$store.commit("setSubMenus", getTempMenu(res.data[0]));
            }
          })
        }
      }
    },
    checkPermission(init) {
      let action = this.action;
      if(!action) {
        this.$router.replace({name: 'Forbidden'});
      } else if(init) {
        this.init();
      }
    },
    init() {
      let conf = this.conf;
      if(conf) {
        document.title = `${conf.name}管理`;
        this.auto = !conf.ni;
        if(typeof conf.listInit === 'function') {
          conf.listInit.call(this);
        }
      } else if(this.type) {
        this.$router.replace({name: 'NotFound'});
      }
    },
    getListeners(item) {
      let res = null;
      if(item.update && item.update.length > 0) {
        res = {};
        item.update.forEach(type => {
          res[type] = this.updateList;
        })
      }
      return res;
    },
    updateList() {
      this.$refs.dataList.getList();
    },
    showDetail({item}) {
      goToDetailRoute(this, {item, type: this.type})
    },
    showEdit(item) {
      goToDetailRoute(this, {item, type: this.type, edit: true})
    },
    encapsulationListeners(listeners) {
      let res = {};
      for(let key in listeners) {
          let handler = listeners[key];
          res[key] = $event => {
              handler.call(this, $event);
          }
      }
      return res;
   },
  }
}
</script>

<style scoped lang="less">
</style>
